import * as React from "react";
import {
  Wrapper,
  PdpWrapper,
  DescriptionWrapper,
ArtistDescription,
  Citations,
  PhotosWrapper,
  CardWrapper,
  ProfilWrapper,
  NameWrapper,
  CarrouselWrapper2,
  LinkWrapper,
  ButtonWrapper,
  TitleWrapper,
  ArtistInfos,
  ImageWrapper,
  MobileProtrait,
  MoreButton,
  More,
} from "./BillBlast.module.css";
import Title from "../../../../components/Title";
import Carrousel from "../../../../components/Carrousel";
import Carousel from 'react-multi-carousel';
import BillBlastPhoto1 from "../../../../res/blast/portrait.png"
import BillBlastPhoto2 from "../../../../res/Photos site/Bill Blast/Bill Blast 2.jpg"
import Button from "./../../../../components/Button";
import PastPresent1 from "../../../../res/blast/past-present-1.png"
import PastPresent2 from "../../../../res/blast/past-present-2.jpg"
import PastPresent3 from "../../../../res/blast/past-present-3.jpg"
import { Helmet } from "react-helmet";

const isBrowser = typeof window !== "undefined";
const infos ={
  path: "BillBlast",
  name: "BillBlast",
  description: 
  "William Cordero aka Bill Blast is an American artist and one of the most prominent New York graffiti artists of the 1980s. Originally from New York City, Cordero was born in 1964 and grew up in Harlem. In the early 1980s, he was part of a group of writers who made the jump to the gallery scene. Bill painted mainly on the walls of basketball courts and has, in fact, very few trains to his credit. In 1982, in what became commonly known as \"Rock Steady Park\", Bill painted two masterpieces on opposite sides of a handball court. The first was titled Sky's the Limit, referring to the lyrics of Keep On by the band D- Train. The painting consisted of several prominent New York City landmarks and created an urban backdrop for the inspirational message inherent in the lyrics. The second mural was titled Eye of the Tiger. Today, the murals are long gone. Bill attended the High School of Art and Design and after working as a consultant on the film Beat Street (1984; directed by Stan Latham), he attended Parsons School of Design where he pursued a major in painting. As his talent and skills as a professional muralist developed, Bill was commissioned to produce large-scale murals for WCVB-TV, the ABC network affiliate in Boston, the Port Authority of New York and New Jersey and Art Train, a traveling museum currently located in Detroit. Bill Blast began exhibiting at the age of nineteen internationally at venues such as Yaki Kornbilt Gallery in Amsterdam, The Netherlands, Boymans Museum in Rotterdam, The Netherlands, Gallozzi La Placa and Fun Gallery in New York City and Boston University in Boston, MA.\nMost recently as part of the exhibition \"Art vs. Transit\", organized by Henry Chalfant for the Bronx Museum of the Arts. His work can be found in public collections such as the Museum of Modern Art in New York, as well as private collections such as the Alain-Dominique Gallizia Collection, the Yaki Kornblit Collection and the Speerstra Collection, among others.",
  pdp: BillBlastPhoto1,
  alt_pdp: "Photo de profil de BillBlast.",
  photos: [
    { src: PastPresent1, name: "BillBlast" },
    { src: PastPresent2, name: "BillBlast" },
    { src: PastPresent3, name: "BillBlast" },
  ],
  citations: []
};

const BillBlast = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
      paritialVisibilityGutter: 0
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      paritialVisibilityGutter: 0
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      paritialVisibilityGutter: 0
    },
    mobile: {
      breakpoint: { max: 428, min: 0 },
      items: 1,
      paritialVisibilityGutter: 0
    },
  };
  return (
    <div className={Wrapper}>
       <Helmet>
        {
            isBrowser ? window.onscroll = function(e){
              var sel = document.querySelector('#f')
              var end = document.querySelector('#end').offsetTop - 450;
              if (window.scrollY > end) {
                  sel.style.position = 'absolute';
                  sel.style.top = end.toString()+ "px";
              } else {
                  sel.style.position = 'fixed';
                  sel.style.top = 0;
              }
            } : null
          }
      </Helmet>
      <div className={CardWrapper}>
        <div className={ProfilWrapper} id="f">
          <img className={PdpWrapper} src={infos.pdp} alt={infos.alt_pdp} />
        </div>
        <div className={ArtistDescription}>
          <h1 className={TitleWrapper}>BILL BLAST <h2 style={{paddingLeft: "16px"}}>(William Cordero - American)</h2></h1>
          <h3 className={NameWrapper} >Born in 1964</h3>
          <img className={MobileProtrait} src={infos.pdp} alt={infos.alt_pdp} />
          <div className={DescriptionWrapper} dangerouslySetInnerHTML={{__html:infos.description}}></div>
          <div  className={Citations}>
            {infos.citations.map((citations) => (
              <div>
                <p style={{fontStyle: "italic", textAlign: "center" }}>{citations}</p>
                <br />
              </div>
            ))}
          </div>
          <br />
        <ul className={ArtistInfos}>
          <p style={{fontWeight: "bolder"}}>EDUCATION</p>
          <br />
          <p>- School of Visual Arts, New York, USA.</p>
          <p>- Parsons School of Design, New York, USA.</p>
          <br/>
          <p style={{fontWeight: "bolder"}}>SOLO EXHIBITIONS</p>
          <br />
          <p style={{fontWeight: "bold"}}>2021</p>
          <p>- Blast on the 4 Line: Futurism and Symbolism in Urban Art, Graffiti virtual gallery, Israel. </p>
          <br/>
          <p style={{fontWeight: "bold"}}>GROUP EXHIBITIONS</p>
          <br />
          <p style={{fontWeight:"bold"}}>2022</p>
          <p>- New York Street Style : création des années 80, Ghost Galerie, Paris, juillet - septembre 2022.</p>
          <br />
          <p style={{fontWeight:"bold"}}>2021</p>
          <p>- Nos Fantômes - exposition inaugurale, Ghost galerie, Paris, France, 10 Septembre - 20 Novembre, 2021.</p>
          <br />
          <p style={{fontWeight:"bold"}}>2016</p>
          <p>- Friends of Antonio, El Museo del Barrio, New York, USA, November 3rd - 27th.</p>
          <br/>
          <p style={{fontWeight: "bold"}}>2012</p>
          <p>- Graffiti: New York 80's, Galerie Jérôme de Noirmont, Paris, France, May 27th - July 20th. (catalogue)</p>
          <br/>
          <br/>
          <p style={{fontWeight: "bolder"}}>PRIVATE COLLECTIONS</p>
          <br />
          <p>- Henk Pijnenburg, Molenrijnselaan, The Netherlands.</p>
          <p>- Yaki Kornblit, Amsterdam, The Netherlands.</p>
          <p>- Gallery Speerstra, Bursins, Switzerland.</p>
          <p>- Gallizia Alain-Dominique, Boulogne-Billancourt, France.</p>
          <br/>
          <br/>
          <div className={More} id="more">
          <p style={{fontWeight: "bolder"}}>PUBLICATIONS</p>
          <br />
          <p style={{fontWeight: "bold"}}>2015</p>
          <p>- Martha Cooper & Joseph Sciorra, Memorial Wall Art.</p>
          <br/>
          <p style={{fontWeight: "bold"}}>2011</p>
          <p>- Galerie Jérôme de Noirmont, Graffiti New York Années 80, Paris, France, May 27th - July 20th</p>
          <br/>
          <p style={{fontWeight: "bold"}}>2002</p>
          <p>- Groninger Museum, Coming From The Subway,</p>
          <br/>
          <p style={{fontWeight: "bold"}}>1987</p>
          <p>- CHALFANT H. and PRIGOFF J., Spraycan Art, Thames and Hudson, London, UK.</p>
          <br/>
          <br/>
          <p style={{fontWeight: "bolder"}}>RESIDENCES</p>
          <br />
          <p style={{fontWeight: "bold"}}>1986</p>
          <p id="end">- Joan Of Arc Junior High School, New York City, USA.</p>
        </div>
        </ul>
        <button className={MoreButton} id="moreBtn" onClick={() => {
                 var sel = document.querySelector('#more');
                 var selBtn = document.querySelector('#moreBtn');
                 console.log("in there");

                
                  if (sel.style.display === "none") {
                    sel.style.display = "inline";
                    selBtn.innerHTML = "Read more";
                  } else {
                    sel.style.display = "none";
                    selBtn.innerHTML = "Read less";
                }
                 
              }}
               >Read more</button>
        </div>
      </div>
      <div className={PhotosWrapper}>
        <Title title={"Past or Present"} />
        <div className={CarrouselWrapper2}>
        <Carousel
            slidesToSlide={1}
            arrows={true}
            swipeable={true}
            draggable={true}
            responsive={responsive}
            infinite={false}
            customTransition="transform 500ms ease-in-out"
            transitionDuration={500}
            autoPlay={true}
            autoPlaySpeed={3000}
            showDots={false}
            partialVisbile={true}
            >
            {/* TODO: Faire les cartel ici */}
            {infos.photos.map((image) => (
                <img
                  className={ImageWrapper}
                  key={image.name}
                  alt={image.name}
                  src={image.src}
                />
            ))}
          </Carousel>
        </div>
      </div>
      <div className={ButtonWrapper}>
            <Button
              alt=""
              onClick={() => {
                if (typeof window !== "undefined") {
                  window.location.href = "/en/contactez-nous";
                }
              }
            }
            >
              Contact-us
            </Button>
        </div>
    </div>
  );
};

export default BillBlast;