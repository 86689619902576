// extracted by mini-css-extract-plugin
export var ArtistDescription = "BillBlast-module--ArtistDescription--oMR2e";
export var ArtistInfos = "BillBlast-module--ArtistInfos--fUqSW";
export var ButtonWrapper = "BillBlast-module--ButtonWrapper --zoilP";
export var CardWrapper = "BillBlast-module--CardWrapper--k3cB7";
export var CarrouselWrapper2 = "BillBlast-module--CarrouselWrapper2--MiXU8";
export var Citations = "BillBlast-module--Citations--ytQnS";
export var DescriptionWrapper = "BillBlast-module--DescriptionWrapper--Yd4T6";
export var ImageWrapper = "BillBlast-module--ImageWrapper--OSGgd";
export var LinkWrapper = "BillBlast-module--LinkWrapper--nz4-3";
export var MobileProtrait = "BillBlast-module--MobileProtrait--RFz0C";
export var More = "BillBlast-module--More--NdtKW";
export var MoreButton = "BillBlast-module--MoreButton--IPXAZ";
export var NameWrapper = "BillBlast-module--NameWrapper--pIUiI";
export var PdpWrapper = "BillBlast-module--PdpWrapper--7odYx";
export var PhotosWrapper = "BillBlast-module--PhotosWrapper--WAE83";
export var ProfilWrapper = "BillBlast-module--ProfilWrapper--ykB07";
export var TitleWrapper = "BillBlast-module--TitleWrapper--kmHhu";
export var Wrapper = "BillBlast-module--Wrapper--pPKYx";